import {ReactElement} from 'react';

import {
  CalculatorIcon,
  CO2Icon,
  CostIcon,
  EconomicIcon,
  GoalIcon,
  GoogleIcon,
  GrowingCropsIcon,
  HomeIcon,
  IdentifyCropIcon,
  MarketplaceIcon,
  ProfileIcon,
  SignOutIcon,
  SimpleCalculations,
  SimpleCrop,
  SimplePound,
} from '../../resources/Icons';

export type IconId =
  | 'glass'
  | 'calculator'
  | 'crops'
  | 'coin'
  | 'economic'
  | 'marketplace'
  | 'simpleCrop'
  | 'simplePound'
  | 'simpleCalculations'
  | 'profile'
  | 'home'
  | 'signOut'
  | 'google'
  | 'co2'
  | 'goal';

const toolIcons: Record<IconId, ReactElement> = {
  glass: <IdentifyCropIcon />,
  calculator: <CalculatorIcon />,
  crops: <GrowingCropsIcon />,
  coin: <CostIcon />,
  simpleCrop: <SimpleCrop />,
  simplePound: <SimplePound />,
  simpleCalculations: <SimpleCalculations />,
  economic: <EconomicIcon />,
  marketplace: <MarketplaceIcon />,
  profile: <ProfileIcon />,
  home: <HomeIcon />,
  signOut: <SignOutIcon />,
  google: <GoogleIcon />,
  co2: <CO2Icon />,
  goal: <GoalIcon />,
};

export default toolIcons;
