import classNames from 'classnames';
import Head from 'next/head';
import {createContext, PropsWithChildren, ReactElement, useCallback, useContext, useState} from 'react';

import Col from '../../common/Col';
import Debug from './Debug';
import Navbar from './Navbar';

type Props = {
  title: string;
  scenarioIndex?: number;
  scenarioId?: string;
  fullHeight?: boolean | 'tabletAndUp';
};

type ContextProps = {
  navBarHeight: number | null;
};

const LayoutContext = createContext<ContextProps>({navBarHeight: null});

export const useLayoutContext = () => {
  return useContext(LayoutContext);
};

const Layout = ({children, title, scenarioIndex, scenarioId, fullHeight = false}: PropsWithChildren<Props>) => {
  const [contextProps, setContextProps] = useState<ContextProps>({navBarHeight: null});

  const handleNavBarHeightChange = useCallback((height: number) => {
    setContextProps(prevState => ({...prevState, navBarHeight: height}));
  }, []);

  return (
    <LayoutContext.Provider value={contextProps}>
      <Head>
        <title key={'title'}>{title} - Envirocrops</title>
      </Head>
      <Col
        className={classNames(
          fullHeight !== false ? 'h-full' : 'min-h-full',
          fullHeight === 'tabletAndUp' && 'mobile:h-auto mobile:min-h-full'
        )}
      >
        <Navbar
          className="flex-none"
          onHeightChange={handleNavBarHeightChange}
          scenarioIndex={scenarioIndex ?? 0 /* TODO: Use a proper fallback */}
        />
        <main className={classNames('flex flex-col grow', fullHeight && 'overflow-y-hidden')}>{children}</main>
        {scenarioId && <Debug scenarioId={scenarioId} />}
      </Col>
    </LayoutContext.Provider>
  );
};

export default Layout;

/**
 * Use this layout in your NextJS page by doing: `YourPage.getLayout = getToolLayout;`
 * This way, there's no need to wrap your page in a <Layout> element
 */
export const getToolLayout = (page: ReactElement, title: string) => (
  <Layout title={title} scenarioIndex={page.props.scenarioIndex} scenarioId={page.props.scenarioId}>
    {page}
  </Layout>
);

export const getToolLayoutFullHeight = (page: ReactElement, title: string) => (
  <Layout title={title} fullHeight={true} scenarioIndex={page.props.scenarioIndex} scenarioId={page.props.scenarioId}>
    {page}
  </Layout>
);

export const getToolLayoutFullHeightExceptMobile = (page: ReactElement, title: string) => {
  return (
    <Layout
      title={title}
      fullHeight={'tabletAndUp'}
      scenarioIndex={page.props.scenarioIndex}
      scenarioId={page.props.scenarioId}
    >
      {page}
    </Layout>
  );
};
