import {useRouter} from 'next/router';
import React, {ReactElement, useCallback, useState} from 'react';

import {GoalIcon, Plant5LeavesIcon, SettingsIcon, WarningSign} from '../../../resources/Icons';
import URLS from '../../../urls';
import Col from '../../common/Col';
import {useFirebaseAuth} from '../../common/EnvironmentProvider';
import Row from '../../common/Row';
import {Flow, UserGoal} from '../../query/graphql';
import {useIsSupplier} from '../../query/supplierHooks';
import useCurrentUser from '../../query/useCurrentUser';
import {useFlowEdited} from '../../query/useFlowEdited';
import {useFlowProgress} from '../../query/useFlowProgress';
import {useAppDispatch} from '../../store';
import {usePlantingStatus, useTrackingProgress} from '../hooks/trackingHooks';
import {useLandReport} from '../hooks/useLandReport';
import toolIcons from '../icons';
import {StartFlowActions} from '../startFlowSlice';
import Button from './buttons/Button';

export type SideNavigationProps = {
  scenarioIndex: number;
  extraItem?: ReactElement;
};

const SideNavigation = (props: SideNavigationProps) => {
  const currentUser = useCurrentUser();
  const scenarioId = currentUser?.scenarios[props.scenarioIndex]?.uuid || currentUser?.scenarios[0]?.uuid;
  const isSupplier = useIsSupplier();

  if (!scenarioId) return null;

  return <SideNavigationContent {...props} scenarioId={scenarioId} isSupplier={isSupplier} />;
};

const SideNavigationContent = ({
  scenarioIndex,
  scenarioId,
  extraItem,
  isSupplier,
}: SideNavigationProps & {scenarioId: string; isSupplier: boolean}) => {
  const firebaseAuth = useFirebaseAuth();
  const dispatch = useAppDispatch();
  const router = useRouter();
  const {asPath} = router;
  const [signingOut, setSigningOut] = useState(false);
  const landFlowProgress = useFlowProgress(scenarioId, Flow.Land);
  const costFlowProgress = useFlowProgress(scenarioId, Flow.Cost);
  const landEdited = useFlowEdited(scenarioId, Flow.Land);
  const costEdited = useFlowEdited(scenarioId, Flow.Cost);
  const {data: plantingStatus} = usePlantingStatus(scenarioId);
  const trackingProgress = useTrackingProgress(scenarioId);

  const {data: landReport} = useLandReport(scenarioId);
  const emissionsEnabled = landReport?.report?.goal === UserGoal.SupplyProperty;

  const buttonVariant = useCallback(
    (buttonURL?: string) => {
      if (!buttonURL || !asPath.startsWith(buttonURL)) return 'secondary';
      return 'primary';
    },
    [asPath]
  );

  const Icon = useCallback(
    (icon: ReactElement) =>
      React.cloneElement(icon, {
        className: 'w-xs h-xs',
      }),
    []
  );

  const AppURLS = URLS.app(scenarioIndex);
  const yieldVariant = buttonVariant(AppURLS.yield);
  const costVariant = buttonVariant(AppURLS.cost);
  const trackVariant = buttonVariant(AppURLS.track);

  const handleSignOut = useCallback(async () => {
    try {
      if (!firebaseAuth) throw new Error('Firebase App not initialized');
      setSigningOut(true);
      await router.push({query: {...router.query, signout: true}});
      await firebaseAuth.signOut(); // This will cause a redirect to Home
      dispatch(StartFlowActions.reset());
    } catch (error) {
      console.error(error);
      setSigningOut(false);
    }
  }, [dispatch, firebaseAuth, router]);

  return (
    <Col className="mobile:w-full w-[21rem] mobile:max-w-none shrink-0 no-text-shadow">
      <Button
        className="mb-xs"
        align="left"
        href={AppURLS.dashboard}
        variant={buttonVariant(AppURLS.dashboard)}
        accessory={Icon(toolIcons.home)}
      >
        Dashboard
      </Button>
      <Button
        align="left"
        href={AppURLS.yield}
        progress={landFlowProgress}
        variant={yieldVariant}
        accessory={Icon(toolIcons.simpleCrop)}
      >
        <Row className="gap-x-4 items-center">
          <div>Yield prediction</div>
          {yieldVariant !== 'primary' &&
            landFlowProgress === 1 &&
            !landEdited &&
            plantingStatus?.finished === false && <WarningSign />}
        </Row>
      </Button>
      <div className="ml-md w-px h-xs bg-primary-700" />
      <Button
        align="left"
        href={AppURLS.cost}
        progress={costFlowProgress}
        variant={costVariant}
        accessory={Icon(toolIcons.simplePound)}
      >
        <Row className="gap-x-4 items-center">
          <div>Cost to grow</div>
          {costVariant !== 'primary' && costFlowProgress === 1 && !costEdited && plantingStatus?.finished === false && (
            <WarningSign />
          )}
        </Row>
      </Button>
      <div className="ml-md w-px h-xs bg-primary-700" />
      <Button
        className="mb-xs"
        align="left"
        href={AppURLS.track}
        progress={trackingProgress}
        variant={trackVariant}
        accessory={Icon(<GoalIcon />)}
      >
        <Row className="gap-x-4 items-center">
          <div>Land tracking</div>
          {/* {... && <WarningSign /> TODO: Work out WarningSign */}
        </Row>
      </Button>
      {emissionsEnabled && (
        <Button
          className="mb-xs"
          align="left"
          href={AppURLS.emissions}
          variant={buttonVariant(AppURLS.emissions)}
          accessory={Icon(toolIcons.co2)}
        >
          Carbon comparison
        </Button>
      )}
      {isSupplier && (
        <Button
          className="mb-xs"
          align="left"
          href={URLS.commonApp.supplier.about}
          variant={buttonVariant(URLS.commonApp.supplier.index)}
          accessory={Icon(<Plant5LeavesIcon />)}
        >
          Supplier profile
        </Button>
      )}
      <Button
        className="mb-xs"
        align="left"
        href={URLS.app(scenarioIndex).settings.profile}
        variant={buttonVariant(URLS.app(scenarioIndex).settings.profile)}
        accessory={Icon(<SettingsIcon />)}
      >
        Settings
      </Button>
      <Button
        className="mb-xs"
        variant="secondary"
        align="left"
        onClick={handleSignOut}
        accessory={Icon(toolIcons.signOut)}
        loading={signingOut}
      >
        Sign out
      </Button>
      {extraItem}
    </Col>
  );
};

export default SideNavigation;
