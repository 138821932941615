import {JsonViewer} from '@textea/json-viewer';
import classNames from 'classnames';
import {CloseIcon} from 'next/dist/client/components/react-dev-overlay/internal/icons/CloseIcon';
import React, {FormEvent, MouseEvent, ReactElement, useCallback, useRef, useState} from 'react';

import Col from '../../common/Col';
import {useEnvironment} from '../../common/EnvironmentProvider';
import Row from '../../common/Row';
import {Flow} from '../../query/graphql';
import useCurrentUser, {useUpdateUserProps} from '../../query/useCurrentUser';
import {useFlowEdited} from '../../query/useFlowEdited';
import {useFlowProgress} from '../../query/useFlowProgress';
import {useResetScenarios, useScenario} from '../../query/useScenario';
import {useStartFlow} from '../decision/startFlow';
import {useCostReport} from '../hooks/useCostReport';
import {useLandReport} from '../hooks/useLandReport';
import {getDebugCurrentDate, removeDebugCurrentDate, setDebugCurrentDate} from '../util/currentSeason';
import {FAKEABLE_TYPES, getFakeYield, setFakeYield} from '../util/fakeYield';
import Button from './buttons/Button';
import SquareButton from './buttons/SquareButton';

const Debug = ({scenarioId}: {scenarioId: string}): ReactElement | null => {
  const environment = useEnvironment();
  const currentUser = useCurrentUser();
  const updateUserProps = useUpdateUserProps();
  const {data: scenario} = useScenario(scenarioId);
  const {data: landReport} = useLandReport(scenarioId);
  const {data: costReport} = useCostReport(scenarioId);
  const landFlowProgress = useFlowProgress(scenarioId, Flow.Land);
  const costFlowProgress = useFlowProgress(scenarioId, Flow.Cost);
  const landEdited = useFlowEdited(scenarioId, Flow.Land);
  const costEdited = useFlowEdited(scenarioId, Flow.Cost);
  const startFlow = useStartFlow();
  const resetScenarios = useResetScenarios();
  const [open, setOpen] = useState(false);

  const dateRef = useRef<HTMLInputElement>(null);
  const debugCurrentDate = getDebugCurrentDate();
  const [formattedDate, setFormattedDate] = useState(
    debugCurrentDate ? new Date(debugCurrentDate).toDateString() : 'None'
  );

  const handleSetDate = useCallback(() => {
    const value = dateRef.current?.value;
    if (!value) {
      removeDebugCurrentDate();
      setFormattedDate('None');
    } else {
      setDebugCurrentDate(value);
      setFormattedDate(new Date(value).toDateString());
    }
  }, []);

  const toggleOpen = useCallback(() => setOpen(prevState => !prevState), []);

  const handleResetTool = useCallback(
    async (event: MouseEvent) => {
      event.stopPropagation();
      await resetScenarios();
    },
    [resetScenarios]
  );

  const handleResetUserProps = useCallback(
    async (event: MouseEvent) => {
      event.stopPropagation();
      await updateUserProps({
        supplierProfileLastSeen: null,
      });
    },
    [updateUserProps]
  );

  const handleSetYield = useCallback((event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!event.target) return;
    const form = event.currentTarget.closest('form');
    if (!form) return;
    const cropId = form.getAttribute('data-crop-id');

    if (!cropId || !Object.keys(FAKEABLE_TYPES).includes(cropId)) {
      console.error(`Cannot set fake yield. Invalid crop ID '${cropId}'`);
      return;
    }
    const data = form.querySelector('input')?.value;
    setFakeYield(cropId as keyof typeof FAKEABLE_TYPES, data ? parseFloat(data as string) : null);
  }, []);

  const handleRemoveYield = useCallback((event: MouseEvent) => {
    if (!event.target) return;
    const form = event.currentTarget.closest('form');
    if (!form) return;
    const cropId = form.getAttribute('data-crop-id');
    if (!cropId || !Object.keys(FAKEABLE_TYPES).includes(cropId)) {
      console.error(`Cannot set fake yield. Invalid crop ID '${cropId}'`);
      return;
    }
    setFakeYield(cropId as keyof typeof FAKEABLE_TYPES, null);
    const input = form.querySelector('input');
    if (input) input.value = '';
  }, []);

  if (!environment?.debug) return null;

  return (
    <div
      className={classNames(
        'fixed bottom-0 right-0 basis-1/6 border bg-gray-50 opacity-90 ml-4 p-1 overflow-scroll cursor-pointer max-w-2xl text-xs z-[10000]',
        {'h-full': open}
      )}
      onClick={!open ? toggleOpen : undefined}
    >
      {!open && (
        <Col>
          <div>DEBUG</div>
          <div>Version: {environment?.version}</div>
        </Col>
      )}
      {open && (
        <Col className={'gap-2'}>
          <Button variant={'navigation'} onClick={toggleOpen}>
            CLOSE
          </Button>
          <div>Version: {environment?.version}</div>
          <Row className={'gap-2'}>
            <Button variant={'secondary'} className={'self-start'} onClick={handleResetTool}>
              Reset tool
            </Button>
            <Button variant={'secondary'} className={'self-start'} onClick={handleResetUserProps}>
              Reset user props
            </Button>
          </Row>
          <div className={'border p-2 flex flex-col gap-2'}>
            <div className={'text-center'}>Fake yields when harvested (green tonnes / ha / yr)</div>
            <form data-crop-id={'srcWillow'} className={'flex flex-row gap-xs items-center'} onSubmit={handleSetYield}>
              SRC Willow
              <input defaultValue={getFakeYield('srcWillow') ?? undefined} />
              <Button type={'submit'} variant={'primary'}>
                Set
              </Button>
              <SquareButton type={'button'} variant={'primary'} onClick={handleRemoveYield}>
                <CloseIcon />
              </SquareButton>
            </form>
            <form data-crop-id={'miscanthus'} className={'flex flex-row gap-xs items-center'} onSubmit={handleSetYield}>
              Miscanthus
              <input name={'miscanthus'} defaultValue={getFakeYield('miscanthus') ?? undefined} />
              <Button type={'submit'} variant={'primary'}>
                Set
              </Button>
              <SquareButton type={'button'} variant={'primary'} onClick={handleRemoveYield}>
                <CloseIcon />
              </SquareButton>
            </form>
            <form data-crop-id={'srcPoplar'} className={'flex flex-row gap-xs items-center'} onSubmit={handleSetYield}>
              SRC Poplar
              <input name={'miscanthus'} defaultValue={getFakeYield('srcPoplar') ?? undefined} />
              <Button type={'submit'} variant={'primary'}>
                Set
              </Button>
              <SquareButton type={'button'} variant={'primary'} onClick={handleRemoveYield}>
                <CloseIcon />
              </SquareButton>
            </form>
          </div>
          <fieldset className={'border p-2 flex flex-col gap-2'}>
            <div>
              Fake current date: <span className={'italic'}>{formattedDate}</span>
            </div>
            <Row className={'gap-xs'}>
              <input ref={dateRef} defaultValue={debugCurrentDate ?? undefined} placeholder={'example: 2026-02-05'} />
              <Button variant={'primary'} onClick={handleSetDate}>
                Set
              </Button>
            </Row>
          </fieldset>
          User:
          <JsonViewer value={currentUser} enableClipboard={false} />
          Start Flow:
          <JsonViewer value={startFlow} enableClipboard={false} />
          Current scenario:
          <pre>{scenarioId}</pre>
          Scenario:
          <JsonViewer value={scenario} enableClipboard={false} />
          Reports:
          <JsonViewer value={{yield: landReport, cost: costReport}} enableClipboard={false} />
          Progress:
          <JsonViewer value={{landFlowProgress, costFlowProgress}} enableClipboard={false} />
          Flows Edited:
          <JsonViewer value={{landEdited, costEdited}} enableClipboard={false} />
        </Col>
      )}
    </div>
  );
};

export default Debug;
