const DEBUG_CURRENT_DATE_TRACKING_KEY = 'debug-current-date-tracking-key'; // Key for session storage

export const setDebugCurrentDate = (date: string) =>
  window.sessionStorage.setItem(DEBUG_CURRENT_DATE_TRACKING_KEY, date);

export const removeDebugCurrentDate = () => window.sessionStorage.removeItem(DEBUG_CURRENT_DATE_TRACKING_KEY);

export const getDebugCurrentDate = (): string | null => window.sessionStorage.getItem(DEBUG_CURRENT_DATE_TRACKING_KEY);

export const getCurrentDate = (): Date => {
  const debugDate = window.sessionStorage.getItem(DEBUG_CURRENT_DATE_TRACKING_KEY);
  if (debugDate) {
    const date = new Date(debugDate);
    if (date.toString() !== 'Invalid Date') {
      return date;
    }
  }
  return new Date();
};

/**
 * Determine the current planting season based on the current date, or the fake date set through debug menu, if any.
 * The number returned is the first year of the season, e.g. 2022 for season 2022/23.
 *
 * Note: Currently, current season just matches current year. If we want something more sophisticated, for instance
 * making the season start in March, just change this function.
 */
export const getCurrentSeason = (): number => {
  return getCurrentDate().getFullYear();
};
