import {Scenario} from '../../query/graphql';
import {CropId, getAllCrops} from '../resources/crops';

type PlantingStatus =
  | {
      finished: true;
      cropId: CropId; // Planted crop
      year: number; // Planting year
    }
  | {
      finished: false;
      cropId: undefined;
      year: undefined;
    };

export const plantingStatus = (tracking: Scenario['tracking']): PlantingStatus => {
  if (tracking) {
    const cropId =
      getAllCrops()[tracking.plantedId as CropId] !== undefined ? (tracking.plantedId as CropId) : undefined;
    if (cropId) {
      return {finished: true, cropId, year: tracking.plantingYear};
    } else {
      console.error(`Tracking contains invalid planted crop ID: ${tracking.plantedId}`);
    }
  }
  return {finished: false, cropId: undefined, year: undefined};
};

export const getPlantingProgress = (tracking?: Scenario['tracking']): number => {
  // Tracking will be null if user has not planted yet, and an array otherwise
  return tracking && plantingStatus(tracking).finished ? 1 : 0;
};
