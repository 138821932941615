import {useQuery, UseQueryResult} from '@tanstack/react-query';

import {useScenarioQuery} from '../../query/graphql';
import {useInputs, useScenarioQueryWithDefaults, useTracking} from '../../query/useScenario';
import {CropId} from '../resources/crops';
import {getPlantingProgress, plantingStatus} from '../util/tracking';
import {useLandReport} from './useLandReport';

export const usePlanningProgress = (scenarioId: string): UseQueryResult<number> => {
  const {data: inputsData, error: inputsError, isLoading: isInputsLoading} = useInputs(scenarioId);
  const {data: landData, error: landError, isLoading: isLandLoading} = useLandReport(scenarioId);

  return useQuery({
    queryKey: [...useScenarioQuery.getKey({uuid: scenarioId}), 'planningProgress', inputsData, !!landData?.report],
    enabled: !isInputsLoading && !isLandLoading,
    queryFn: () => {
      if (inputsError) throw inputsError;
      if (landError) throw landError;

      if (!inputsData || !landData?.report) return 0;
      const {alreadyPlanted, plantedCropId, plantingYear} = inputsData;
      const inputList = [alreadyPlanted, plantedCropId, plantingYear];
      return inputList.filter(input => input !== null).length / inputList.length;
    },
  });
};

export const usePlantingProgress = (scenarioId: string): number => {
  const {data: tracking} = useTracking(scenarioId);
  const {data: planningFinished} = usePlanningFinished(scenarioId);
  return planningFinished && getPlantingProgress(tracking) ? 1 : 0;
};

export const useYieldInputProgress = (scenarioId: string): number => {
  const {data: tracking} = useTracking(scenarioId);
  const {data: inputs} = useInputs(scenarioId);
  const {data: landReportData} = useLandReport(scenarioId);
  const landReport = landReportData?.report;

  if (!tracking || !inputs || !landReport) return 0;

  const {plantedCropId} = inputs;
  if (!plantedCropId) return 0;

  const plantationLifetime = landReport.results[plantedCropId as CropId]?.annualHarvest.length;
  const registeredYears = tracking.years.filter(y => y !== null).length ?? 0;
  return !plantationLifetime ? 0 : registeredYears / plantationLifetime;
};

/**
 * Combined progress of Land Tracking. This is the average of the progress of the three steps: Planning, Planting and
 * Yield Input.
 */
export const useTrackingProgress = (scenarioId: string): number => {
  const {data: planningProgress} = usePlanningProgress(scenarioId);
  const plantingProgress = usePlantingProgress(scenarioId);
  const inputProgress = useYieldInputProgress(scenarioId);

  return ((planningProgress ?? 0) + plantingProgress + inputProgress) / 3;
};

export const usePlanningFinished = (scenarioId: string): UseQueryResult<boolean> => {
  const {data: progress, isLoading, error} = usePlanningProgress(scenarioId);
  return useQuery({
    queryKey: [...useScenarioQuery.getKey({uuid: scenarioId}), 'planningFinished', progress],
    enabled: !isLoading,
    queryFn: () => {
      if (error) throw error;
      return (progress ?? 0) >= 1;
    },
  });
};

export const usePlantingStatus = (scenarioId: string) => {
  return useScenarioQueryWithDefaults(scenarioId, {
    select: data => plantingStatus(data.scenario.tracking),
  });
};
